.header {
  position: sticky;
  top: 0;
  z-index: 2;
  height: 6em;
  max-width: 1400px;
  width: 100%;
  padding: 0 1em;
  margin: 0 auto;
  justify-content: space-between;
}
