.projects {
  scroll-margin: 8em;
}
.projects__grid {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18em, 1fr));
  grid-gap: 2em;
}

/* @media only screen and (min-width: 600px) and (max-width: 390px) {
  .projects__grid {
    grid-template-columns: none;
  }
} */
