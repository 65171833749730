.skills {
  scroll-margin: 8em;
}
.skills__list {
  max-width: 1000px;
  /* width: 98%; */
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: center;
}

.skills__list-item > div {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: center;
}

.skill__image {
  /* width: 100px; */
  height: 50px;
  object-fit: fill;
}
@media (max-width: 600px) {
  .skills__list-item {
    width: 80px;
  }
  .skills__list-item h4 {
    font-size: 0.6em;
  }
}
